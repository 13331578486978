<template>
  <div class="content">
    <div class="list">
      <img
        src="https://ppyos.xijiuyou.com/202202/vip-rule-title.jpg"
        alt=""
      />
      <p class="title">会员等级介绍</p>
      <p class="dian">
        <span class="top"></span> 会员等级是基于本平台用户在一定时期内获得经验值所决定，用户达到相应的等级门槛即可升级。用户可以通过完成 <span>每日任务、在赚{{unitauthorize("钱")}}频道赚取奖励</span> 来获取经验值，从而提升等级。
      </p>
      <p class="title">经验值说明</p>
      <p>用来评定用户会员等级的值称为经验值，获得经验值的方式主要有以下2种：</p>
      <p class="dian">
        <span class="top"></span>
        <span>完成每日任务，并领取对应经验值</span> ；注意，若完成当日不及时领取，次日即过期，不可领取；
      </p>
      <p class="dian">
        <span class="top"></span>
        通过{{unitauthorize("赚钱")}}频道累计赚取奖励（仅计算高额奖励和充值奖励）≥1000{{unitauthorize("元")}}后，<span>每赚取1{{unitauthorize("元")}}，即获得1经验值，上不封顶。</span>
      </p>
      <p>
        若期间出现奖励扣回情况，也会相应扣除对应经验值，扣除后如等级发生变化，即刻生效。
      </p>
      <p>
        请勿违规刷经验值，如发现存在违规行为，我们有权取消你获得经验值的资格。
      </p>
      <p class="title">等级有效期</p>
      <p>
        会员等级以用户 <span>近90个自然日内获得的经验值</span> 合计确定，即90天前获得的经验值将不再计入会员等级核算。若用户当日新增经验值等级升级，新等级从升级当天开始计算。
      </p>
      <p class="title">等级权益</p>
      <p>
        不同的会员可享受该等级专属权益，具体权益请以页面展示为准。用户经验值达到对应升级门槛，即立即升级。
      </p>
      <!-- <p class="dian"><span class="top"></span><strong>加成收益特权：</strong> 不同等级会员默认收益加成比例不同，具体以权益页面展示内容为准；特权无需激活，到达该等级即刻享受，加成收益需要主动领取；</p> -->
      <!-- <p class="dian"><span class="top"></span><strong>加成收益卡：</strong>不同等级会员每月可领取1张不同规格的加成收益卡，加成收益卡领取后需要激活才可以生效；</p> -->
      <p class="dian"><span class="top"></span><strong>话费充值：</strong>不同等级会员话费充值享受折扣不同，具体以权益页面展示内容为准；</p>
      <p class="dian"><span class="top"></span><strong>小游戏免广告：</strong>不同等级会员可每月可领取不同次数不同天数小游戏免广告券，参与小游戏时不看广告即可复活或使用道具等；</p>
      <p class="dian"><span class="top"></span><strong>{{unitauthorize("提现")}}特权：</strong>本平台会员即可享受{{unitauthorize("整元")}}全额提现功能；</p>
      <p class="dian"><span class="top"></span><strong>话费兑换：</strong>本平台会员使用{{unitauthorize("余额")}}充值话费，即可享受折上99.5%的优惠折扣</p>
      <p class="title">会员等级对照表</p>
      <img class="bottom" src="https://ppyos.xijiuyou.com/202202/vip-rule-detail.jpg" alt="">
    </div>
  </div>
</template>
<script>
import unit from "../utils/unit";
export default {
  data() {
    return {};
  },
  methods: {
    unitauthorize(name){
      return unit.authorize(name)
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(270deg, #111111 1%, #333333 100%);
  padding: 12px;
  .list {
    width: 100%;
    background: #ffffff;
    box-shadow: inset 0px 2px 0px 0px #ffffff;
    border-radius: 15px;
    padding: 0px 0 18px;
    text-align: center;
    .title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: bold;
      color: #444444;
      line-height: 30px;
      margin: 8px 0 0;
    }
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      line-height: 25px;
      padding: 0 27px;
      text-align: left;
    }
    .dian{
        .top{
          width: 4px;
          height: 4px;
          border-radius: 50%;
          display: inline-block;
          line-height: 20px;
          background: #000000;
          float: left;
          margin-top: 10px;
          margin-right: 10px;
        }
        span{
          color: red;
        }
    }
    img {
      width: 100%;
      margin: 0 auto 6px;
    }
    .bottom{
        width: calc(100% - 40px);
        margin: 0;
        margin-top: 7px;
    }
  }
}
</style>